@tailwind base;
@tailwind components;
@tailwind utilities;

.Input {
    @apply w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500;
}

.input-error {
    @apply border-red-500 focus:border-red-500;
}

.error {
    @apply text-red-700;
}

.btn {
    @apply block w-full px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase rounded shadow hover:shadow-lg focus:outline-none;
}

.btn-table {
    @apply block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase rounded shadow hover:shadow-lg focus:outline-none;
}

.input-invalid {
    border: 1px solid red;
    background-color: #fda49a;
}

.form-control {
    @apply rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full;
}

.rdtPicker {
    position: relative !important;
    margin-top: -235px !important;
}

.lista-pagos .rdtPicker {
    position: absolute !important;
    margin-top: 0 !important;
}

.clientes .rdtPicker {
    position: absolute !important;
    margin-top: 0 !important;
    right: 0;
}

tr:nth-child(even) {
    @apply bg-gray-200;
}

.pago-externo .form-control {
    @apply bg-white w-full;
    padding-top: 0;
}

.pago-externo tr:nth-child(even) {
    @apply bg-white;
}

.pago-externo .rdtPicker {
    width: 280px;
}
.ReactModal__Overlay ReactModal__Overlay--after-open {
    z-index: 99999999;
}
