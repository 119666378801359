.Loader-xs {
    position: relative;
    width: 15px;
    height: 15px;
    margin: 55px auto;
    border-radius: 100%;
    box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
        15px -15px rgb(223, 223, 223);
    -o-box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
        15px -15px rgb(223, 223, 223);
    -ms-box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
        15px -15px rgb(223, 223, 223);
    -webkit-box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
        15px -15px rgb(223, 223, 223);
    -moz-box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
        15px -15px rgb(223, 223, 223);
    animation: cssload-spin ease infinite 4.6s;
    -o-animation: cssload-spin ease infinite 4.6s;
    -ms-animation: cssload-spin ease infinite 4.6s;
    -webkit-animation: cssload-spin ease infinite 4.6s;
    -moz-animation: cssload-spin ease infinite 4.6s;
}

@keyframes cssload-spin {
    0%,
    100% {
        box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
            15px -15px rgb(223, 223, 223);
    }
    25% {
        box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223),
            15px 15px rgb(79, 77, 73);
    }
    50% {
        box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73),
            -15px 15px rgb(223, 223, 223);
    }
    75% {
        box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
    }
}

@-o-keyframes cssload-spin {
    0%,
    100% {
        box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
            15px -15px rgb(223, 223, 223);
    }
    25% {
        box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223),
            15px 15px rgb(79, 77, 73);
    }
    50% {
        box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73),
            -15px 15px rgb(223, 223, 223);
    }
    75% {
        box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
    }
}

@-ms-keyframes cssload-spin {
    0%,
    100% {
        box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
            15px -15px rgb(223, 223, 223);
    }
    25% {
        box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223),
            15px 15px rgb(79, 77, 73);
    }
    50% {
        box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73),
            -15px 15px rgb(223, 223, 223);
    }
    75% {
        box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
    }
}

@-webkit-keyframes cssload-spin {
    0%,
    100% {
        box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
            15px -15px rgb(223, 223, 223);
    }
    25% {
        box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223),
            15px 15px rgb(79, 77, 73);
    }
    50% {
        box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73),
            -15px 15px rgb(223, 223, 223);
    }
    75% {
        box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
    }
}

@-moz-keyframes cssload-spin {
    0%,
    100% {
        box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73),
            15px -15px rgb(223, 223, 223);
    }
    25% {
        box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223),
            15px 15px rgb(79, 77, 73);
    }
    50% {
        box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73),
            -15px 15px rgb(223, 223, 223);
    }
    75% {
        box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
    }
}
